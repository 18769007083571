import React from 'react'
import tw, { css } from 'twin.macro'
import { StyledSection, h2Styles, paragraphStyles, mediaQuery } from '../../styles'
import contentImg from '../../assets/brands/whatwedo/what-we-do-branded-content.jpg'
import entertainmentImg from '../../assets/brands/whatwedo/what-we-do-brand-entertainment.jpg'

const innerSectionStyles = css`
  ${tw`w-full relative flex flex-col justify-center items-center lg:grid lg:grid-cols-2 gap-10`};
  margin-bottom: 3.75rem;
`

const serviceBoxStyles = css`
  ${tw`flex flex-col justify-end relative border border-white`};
  padding: 2em;
  min-height: 25rem;
  background: linear-gradient(180deg, rgb(18 18 18 / 50%) 100%, rgb(18 18 18 / 0%) 0);
  ${mediaQuery(
    `
    aspect-ratio: 1;
    min-height: auto;
  `,
    'laptop'
  )};

  & img {
    transition: 0.3s ease-in-out;
  }

  &:hover {
    > img {
      ${tw`lg:opacity-75`};
    }
  }
`

const imgStyles = css`
  ${tw`w-full h-full absolute inset-0 object-cover opacity-75 lg:opacity-0 transition-opacity duration-200`};
  z-index: -1;
  transform: rotate(-5deg);
`

const contentImgStyles = css`
  transform: rotate(-10deg);
`

const entertainmentImgStyles = css`
  transform: rotate(10deg);
`

const WhatWeDo = ({ id }) => {
  return (
    <StyledSection css={innerSectionStyles} id={id}>
      <div css={serviceBoxStyles}>
        <div tw="z-10">
          <h3 css={h2Styles} tw="pb-6">
            Branded
            <br /> Content
          </h3>
          <p css={paragraphStyles}>
            We create engaging stories in any format, placing the audience at the center of our work
            and crafting for both organic distribution and paid media formats.
          </p>
        </div>
        <img src={contentImg} alt="" css={[imgStyles, contentImgStyles]} />
      </div>

      <div css={serviceBoxStyles}>
        <div tw="z-10">
          <h3 css={h2Styles} tw="pb-6">
            Brand
            <br /> Entertainment
          </h3>
          <p css={paragraphStyles}>
            We bring brands into a new promotion model for entertainment furthering their reach
            organically as well as driving profitability through their content IP.
          </p>
        </div>
        <img src={entertainmentImg} alt="" css={[imgStyles, entertainmentImgStyles]} />
      </div>
    </StyledSection>
  )
}

export { WhatWeDo }
