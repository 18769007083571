import React from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'
import { useStaticQuery, graphql } from 'gatsby'
import Slider from '../Slider'
import tw, { css } from 'twin.macro'
import { StyledSection, h2Styles, paragraphStyles, mediaQuery } from '../../styles'
import ServiceTemplate from '../../templates/ServiceTemplate'

const GET_SERVICES = graphql`
  query ServicesQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "service" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            templateKey
            date(formatString: "MMMM DD, YYYY")
            title
            description
            link
            featuredimage {
              publicURL
            }
            video {
              publicURL
            }
          }
        }
      }
    }
  }
`

const topParagraphStyles = css`
  ${tw`m-auto`}
  ${mediaQuery(`
    max-width: 38rem;
  `)}
`

const featuresSectionStyles = css`
  ${tw`py-12 px-5 md:px-0 md:grid md:grid-cols-6 md:gap-8`}
  ${mediaQuery(
    `
    margin-right: -12vw;
  `,
    'tablet-landscape'
  )}
  ${mediaQuery(
    `
    margin-right: 0;
  `,
    'laptop'
  )}

  & div {
    ${tw`mb-6`}

    & > h4 {
      ${tw`mb-3`}
    }
  }
`

const sliderWrapperStyles = css`
  ${tw`block`}
  cursor: grab;
  ${mediaQuery(
    `
    margin-right: -12vw;
    `
  )}
  ${mediaQuery(
    `
    cursor: default;
  `,
    'laptop'
  )}
`

const sliderSettings = {
  loop: false,
  spaceBetween: 32,
  watchOverflow: true,
  keyboard: {
    enabled: true,
    onlyInViewport: false
  },
  slidesPerView: 2,
  breakpoints: {
    640: {
      slidesPerView: 3
    },
    1024: {
      slidesPerView: 7
    }
  }
}

const HowItWorks = ({ id }) => {
  const data = useStaticQuery(GET_SERVICES)
  const {
    allMarkdownRemark: { edges: services }
  } = data || {}

  return (
    <StyledSection id={id}>
      <h3 css={[h2Styles, topParagraphStyles]}>
        We create projects from start to finish, launch them to the market in an agile manner.
      </h3>
      <div css={featuresSectionStyles}>
        <div tw="col-span-2">
          <h4 css={h2Styles}>Data</h4>
          <p css={paragraphStyles}>Data-Driven strategies to create impact stories.</p>
        </div>
        <div tw="col-span-2">
          <h4 css={h2Styles}>Creativity</h4>
          <p css={paragraphStyles}>
            Powerful storytelling to turn the conventional into unexpected.
          </p>
        </div>
        <div tw="col-span-2">
          <h4 css={h2Styles}>Content</h4>
          <p css={paragraphStyles}>Best content, focused on driving the bests results.</p>
        </div>
      </div>

      <div css={sliderWrapperStyles}>
        <Slider settings={sliderSettings}>
          {services.map(({ node: service }) => {
            const {
              frontmatter: {
                title,
                description,
                link,
                featuredimage: { publicURL: featuredimage },
                video: { publicURL: video }
              }
            } = service

            const serviceData = {
              title,
              description,
              featuredimage,
              video,
              link
            }

            return <ServiceTemplate {...serviceData} key={uuid()} />
          })}
        </Slider>
        <p tw="text-center mt-8 lg:hidden">Drag left {'>>'}</p>
      </div>
    </StyledSection>
  )
}

HowItWorks.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
}

export { HowItWorks }
