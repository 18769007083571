import React from 'react'
import tw, { css } from 'twin.macro'
import { colors } from '../../../config/design-system'
import { toRem, inputTextStyles, btnStyles, mediaQuery } from '../../styles'
import HubspotContactForm from './HubspotContactForm'

const formStyles = css`
  ${inputTextStyles};
  ${tw`w-full`};
  max-width: ${toRem(680)};

  form {
    fieldset {
      max-width: 100%;

      .hs-form-field {
        width: 100% !important;
        ${tw`w-full my-8 md:my-5`}
      }

      input,
      textarea,
      select {
        ${inputTextStyles};
        ${tw`w-full bg-transparent border-b border-light-text-color py-1 px-1`};
        width: 100% !important;

        &:focus-visible {
          outline-style: groove;
          outline-color: ${colors['accent-color']};
          border-bottom: 1px solid transparent;
        }
      }

      textarea {
        height: ${toRem(150)};
      }

      .is-placeholder {
        color: #ababab;
      }

      .hs-form-booleancheckbox-display {
        ${tw`flex items-baseline`}
      }
    }

    .legal-consent-container {
      font-size: 1rem;

      input {
        width: auto !important;
      }
    }

    .hs-submit {
      ${tw`my-5 relative`}
      .actions {
        &:after {
          ${tw`w-0 absolute bottom-0 left-0 bg-white opacity-0`};
          content: '';
          height: 1px;
          transform: translateX(-100%);
        }

        &:hover:after {
          ${tw`w-full lg:w-1/2 opacity-100`};
          transform: translateX(0%);
          transition: 0.5s;
        }

        & .hs-button {
          ${[btnStyles]}
          ${tw`uppercase cursor-pointer`}
        font-size: 1rem;
          ${mediaQuery(
            `
          font-size: 1.125rem;
        `,
            'tablet-portrait'
          )}
        }
      }
    }
  }
`

const ContactForm = () => {
  return (
    <div css={formStyles}>
      <HubspotContactForm
        region="eu1"
        portalId="25094509"
        formId="e63a8403-20d4-4d4f-89c6-5d2bcfb13c13"
      />
    </div>
  )
}

export default ContactForm
