import React from 'react'
import PropTypes from 'prop-types'
import tw, { css } from 'twin.macro'
import { paragraphStyles, mediaQuery } from '../styles'

const serviceWrapperStyles = css`
  ${tw`relative`};
  height: 72vh;
  min-height: 37.5rem;
  ${mediaQuery(
    `
    min-height: 40.625rem;
  `,
    'phone'
  )}
  ${mediaQuery(
    `
    min-height: 37.5rem;
  `,
    'tablet-portrait'
  )}
  ${mediaQuery(
    `
    min-height: 32.5rem;
    max-height: 32.5rem;
  `,
    'laptop'
  )}
  ${mediaQuery(
    `
    height: 55vh;
  `,
    'big-desktop'
  )}
  ${mediaQuery(
    `
    max-height: unset;
    height: 65vh;
  `,
    '4k'
  )}
`

const serviceTitleStyles = css`
  ${tw`mb-2`}
  font-family: GT Flexa;
  font-size: clamp(1.25rem, 1.6vw, 2.2vw);
  font-weight: 400;
`

const ServiceTemplate = ({ title, description, video, featuredimage }) => {
  return (
    <div css={serviceWrapperStyles}>
      <div tw="rounded-xl mb-5 overflow-hidden">
        <video
          src={video}
          onMouseEnter={({ target: video }) => video.play()}
          onMouseLeave={({ target: video }) => video.pause()}
          type="video/mp4"
          loop
          muted
          playsInline
          poster={featuredimage}
        />
      </div>
      <h4 css={serviceTitleStyles}>{title}</h4>
      <p css={paragraphStyles}>{description}</p>
    </div>
  )
}

ServiceTemplate.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  video: PropTypes.string,
  featuredimage: PropTypes.string
}

export default ServiceTemplate
