const toMatrix = (arr, width) =>
  arr.reduce(
    (rows, key, index) =>
      (index % width === 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) && rows,
    []
  )

const formatEmbeddedVideoUrl = (video) => {
  let formattedVideo = video.replace('watch?v=', 'embed/')

  if (formattedVideo.indexOf('&') > 0) {
    formattedVideo = formattedVideo?.substring(0, formattedVideo.indexOf('&'))
  }

  return formattedVideo
}

const windowExists = typeof window !== 'undefined'

const isExternalUrl = (url, location) => {
  const match = url.match(/^([^:/?#]+:)?(?:\/\/([^/?#]*))?([^?#]+)?(\?[^#]*)?(#.*)?/)
  const { protocol, host } = location
  if (typeof match[1] === 'string' && match[1].length > 0 && match[1].toLowerCase() !== protocol)
    return true
  if (
    typeof match[2] === 'string' &&
    match[2].length > 0 &&
    match[2].replace(new RegExp(`:(${{ 'http:': 80, 'https:': 443 }[protocol]})?$`), '') !== host
  )
    return true
  return false
}

const popupWindow = ({ url, title, viewportWidth, viewportHeight, ...getSizeParams }) => {
  const getPopupSize = ({ maxWidth, maxHeight, minWidth = 350, minHeight = 400 }) => ({
    width: Math.min(Math.max(viewportWidth / 1.2, minWidth), maxWidth),
    height: Math.min(Math.max(viewportHeight / 1.3, minHeight), maxHeight)
  })

  const { width, height } = getPopupSize(getSizeParams)
  const left = viewportWidth / 2 - width / 2
  const top = viewportHeight / 2 - height / 2
  return window.open(
    url,
    title,
    `toolbar=0, status=0, width=${width}, height=${height}, top=${top}, left=${left}`
  )
}

export { toMatrix, formatEmbeddedVideoUrl, windowExists, isExternalUrl, popupWindow }
