/* eslint-disable jsx-a11y/no-noninteractive-element-interactions  */
import React from 'react'
import tw, { css } from 'twin.macro'
import { ScrollTrigger, Tween } from 'react-gsap'
import { windowExists } from '../utils/ui-utils'
import { toRem, h2Styles, mediaQuery } from '../styles'

const listItemStyles = css`
  ${tw`flex items-center relative lg:cursor-pointer lg:py-6 lg:static opacity-50`};
  min-height: 12rem;
  transition: opacity 0.2s ease-in-out;
  ${mediaQuery(
    `
    min-height: 0;
  `,
    'laptop'
  )}

  &.activeItem {
    ${tw`opacity-100`};
    > div {
      ${tw`bg-accent-color border-accent-color`};
    }
    > img {
      ${tw`opacity-100`};
      transform: translateY(-50%) rotate(-5deg);
    }
    svg {
      ${tw`opacity-100`};
    }
  }

  &:hover {
    ${tw`lg:opacity-100`};
    > div {
      ${tw`lg:bg-accent-color lg:border-accent-color`};
    }
    > img {
      ${tw`lg:opacity-100`};
      ${mediaQuery(
        `
        transform: translateY(-50%) rotate(-5deg);
      `,
        'laptop'
      )}
    }
    svg {
      ${tw`lg:opacity-100`};
    }
  }

  &:nth-of-type(2n) {
    &.activeItem {
      > img {
        transform: translateY(-50%) rotate(5deg);
      }
    }
    &:hover {
      > img {
        ${mediaQuery(
          `
          transform: translateY(-50%) rotate(5deg);
        `,
          'laptop'
        )}
      }
    }
  }
`

const listItemNumStyles = css`
  ${tw`flex justify-center items-center font-heading font-thin bg-accent-color border border-accent-color bg-transparent border-light-bg-color mr-5 z-10`}
  flex-shrink: 0;
  font-size: ${toRem(28)};
  border-radius: 50%;
  width: ${toRem(39)};
  height: ${toRem(39)};
  transition: all 0.2s ease-in-out;
`

const listItemImgStyles = css`
  ${tw`absolute pointer-events-none right-0 opacity-0`};
  width: 70%;
  max-width: ${toRem(368)};
  top: 50%;
  transform: translateY(-50%) rotate(15deg);
  transition: opacity 0.3s ease-in-out, transform 0.4s cubic-bezier(0.51, 0.92, 0.98, 1.02);
  z-index: -1;
  filter: brightness(0.8);

  ${mediaQuery(
    `
    width: 40%;
    `,
    'tablet-portrait'
  )}

  ${mediaQuery(`
    min-width: ${toRem(220)};
  `)}
`

const mqlMatchesDesktopUp = windowExists && window.matchMedia('(min-width: 1024px)').matches

const HowToApplyList = ({ listItems }) => {
  const scrollTriggerAttributes = (id) => {
    return {
      mobile: {
        trigger: `#listItem-${id}`,
        start: 'top center',
        end: '+=190',
        fastScrollEnd: '2000',
        preventOverlaps: true,
        toggleClass: 'activeItem',
        toggleActions: 'play reset resume reset',
        scrub: true
      },
      desktop: {
        toggleClass: id === 1 && 'activeItem',
        end: 'top 500',
        once: true
      }
    }[mqlMatchesDesktopUp ? 'desktop' : 'mobile']
  }

  return (
    <ol
      tw="relative"
      onMouseEnter={(e) =>
        mqlMatchesDesktopUp &&
        e.currentTarget.childNodes.forEach((child) => child.classList.remove('activeItem'))
      }
    >
      {listItems.map(({ id, content, img, alt }) => (
        <ScrollTrigger key={id} {...scrollTriggerAttributes(id)}>
          <Tween>
            <li key={id} id={`listItem-${id}`} css={listItemStyles}>
              <div css={listItemNumStyles}></div>
              <p css={h2Styles} tw="relative z-10">
                {content()}
              </p>
              <img src={img} alt={alt} css={listItemImgStyles} />
            </li>
          </Tween>
        </ScrollTrigger>
      ))}
    </ol>
  )
}

export default HowToApplyList
