import React, { useState } from 'react'
import tw, { css } from 'twin.macro'
import { Tween, ScrollTrigger } from 'react-gsap'
import { toRem, mediaQuery, StyledSection, h2Styles, h3Styles, paragraphStyles } from '../../styles'
import { useVideoForViewport } from '../../utils/hooks'
import backgroundVideoSm from '../../assets/creators/particle-wave-mobile.mp4'
import backgroundVideoLg from '../../assets/creators/particle-wave.mp4'

const videoStyles = css`
  ${tw`absolute bottom-0`}
  width: 100%;
  opacity: 9;
  max-width: max-content;
  mix-blend-mode: lighten;
  z-index: -1;
  ${mediaQuery(`
    width: 110%;
    top: 45vh;
  `)}
`

const underlineStyles = css`
  ${tw`absolute`};
  width: 85%;
  left: 48%;
  bottom: -20%;
  transform: translateX(-50%);
  transition: opacity 0.2s ease-in-out;
`

const counters = [
  {
    id: 1,
    title: 'Awards',
    count: 100
  },
  {
    id: 2,
    title: 'Specialties',
    count: 50
  },
  {
    id: 3,
    title: 'Cities',
    count: 150
  }
]

const OurCommunity = ({ id, withPlatforms = false }) => {
  const defaultCountersState = counters.reduce((acc, { id, count }) => {
    acc[id] = { count: 0, finalValue: count }
    return acc
  }, {})

  const [countersState, SetCountersState] = useState(defaultCountersState)

  const handleUpdateCounters = () => {
    setInterval(() => {
      const updatedCounterState = Object.entries(countersState).reduce((acc, [key, value]) => {
        const { finalValue } = value
        acc[key] = {
          ...value,
          count: value.count < finalValue ? value.count++ : finalValue
        }
        return acc
      }, {})

      SetCountersState(updatedCounterState)
    }, 15)
  }

  const videoSrc = useVideoForViewport(backgroundVideoSm, backgroundVideoLg)

  return (
    <div id={id} tw="relative mb-16 overflow-x-hidden">
      <ScrollTrigger
        trigger={`#${id}`}
        start="top 50%"
        onEnter={({ progress }) => {
          const inViewPort = progress > 0 // progress takes values between 0 and 1; 1 being completed.
          const inProgressCounters = counters.some(
            ({ id, count }) => countersState[id].count < count
          ) // some of the counters count hasn't reach its finalValue

          if (inViewPort && inProgressCounters) {
            handleUpdateCounters()
          }
        }}
      >
        <StyledSection tw="flex flex-col justify-center items-center relative">
          <video
            src={videoSrc}
            type="video/mp4"
            css={videoStyles}
            loop
            autoPlay
            muted
            playsInline
          />
          <div>
            <p css={paragraphStyles} tw="font-thin mb-5">
              Creative Community
            </p>
            <h3 css={h2Styles}>
              We curate a world-class pool of creators focused on delivering the best results for{' '}
              <span tw="inline-flex relative">
                <span>brands</span>
                <svg
                  viewBox="0 0 128 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  css={underlineStyles}
                >
                  <path
                    d="M0.0748643 6.99963C39.5656 2.1171 59.3771 0.981767 82.4999 0.645837C107.906 0.27673 118.362 3.0748 127.072 4.86348"
                    stroke="#E55708"
                  />
                </svg>
              </span>
              {withPlatforms && (
                <>
                  <span> and </span>
                  <span tw="inline-flex relative">
                    <span>platforms</span>
                    <svg
                      viewBox="0 0 128 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      css={underlineStyles}
                    >
                      <path
                        d="M0.0748643 6.99963C39.5656 2.1171 59.3771 0.981767 82.4999 0.645837C107.906 0.27673 118.362 3.0748 127.072 4.86348"
                        stroke="#E55708"
                      />
                    </svg>
                  </span>
                </>
              )}
              .
            </h3>
          </div>
          <div tw="flex flex-col items-center w-full gap-x-12 mt-20 lg:mt-24 lg:items-start lg:flex-row lg:justify-end">
            {counters.map(({ id, title }) => (
              <Tween key={id}>
                <div
                  css={css`
                    flex-basis: 25%;
                  `}
                  tw="w-full my-5 px-8 lg:p-0"
                >
                  <strong tw="flex flex-col items-end lg:items-start lg:text-right">
                    <span
                      tw="font-heading w-full font-thin lg:w-auto lg:w-auto"
                      css={css`
                        font-size: ${toRem(48)};
                      `}
                    >
                      +{countersState[id].count}
                    </span>
                    <hr tw="w-full border-light-bg-color my-3" />
                    <span tw="lg:w-full" css={h3Styles}>
                      {title}
                    </span>
                  </strong>
                </div>
              </Tween>
            ))}
          </div>
        </StyledSection>
      </ScrollTrigger>
    </div>
  )
}

export { OurCommunity }
