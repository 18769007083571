import React from 'react'
import tw, { css } from 'twin.macro'
import { toRem, mediaQuery } from '../styles'
import ConctactForm from '../components/forms/ContactForm'

const wrapperStyles = css`
  ${tw`flex flex-col items-center w-full pr-10 lg:flex-row lg:items-start`}
  max-width: ${toRem(675)};
  ${mediaQuery(
    `
    max-width: unset;
  `,
    'laptop'
  )};
`

const titleContainerStyles = css`
  ${tw`flex flex-col justify-center`};
  width: 100%;
  ${mediaQuery(
    `
    width: 30%;
  `,
    'laptop'
  )};
`

const titleStyles = css`
  font-size: ${toRem(28)};
  ${mediaQuery(
    `
    font-size: 2.25rem;
  `,
    'tablet-portrait'
  )};
`

const formContainerStyles = css`
  ${tw`flex flex-col lg:justify-center lg:items-center`};
  width: 100%;
  ${mediaQuery(
    `
    width: 70%;
  `,
    'laptop'
  )};
`

const underlineStyles = css`
  ${tw`absolute`};
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
`

const Contact = () => {
  return (
    <section tw="flex flex-col items-center min-h-screen pt-40 pb-32">
      <div css={wrapperStyles}>
        <div css={titleContainerStyles}>
          <div css={titleStyles}>
            <span tw="flex font-subheading font-thin leading-none">We'd love to</span>
            <strong tw="font-heading font-medium">
              <span>hear from </span>
              <span tw="relative">
                you
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="84"
                  height="35"
                  fill="none"
                  css={underlineStyles}
                  tw="sm:hidden"
                >
                  <path
                    stroke="#E55708"
                    d="M77.3 16.219C63.114-2.38 8.442-5.023 1.043 14.746-3.894 31.437 38.656 39.598 71.91 28.98c33.253-10.618-19.37-32.8-53.4-23.769"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="105"
                  height="42"
                  fill="none"
                  css={underlineStyles}
                  tw="hidden sm:flex"
                >
                  <path
                    stroke="#E55708"
                    d="M97.689 19.371C79.793-3.317 10.687-6.446 1.297 17.713-4.976 38.11 48.797 48 90.852 34.968c42.056-13.03-24.425-40.025-67.458-28.932"
                  />
                </svg>
              </span>
            </strong>
          </div>
        </div>
        <div css={formContainerStyles}>
          <ConctactForm />
        </div>
      </div>
    </section>
  )
}

export default Contact
