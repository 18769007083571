/* eslint-disable import/no-unresolved */
import React from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'

// Import Swiper core and required modules
import { Navigation, A11y, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'

const Slider = ({ children, settings }) => {
  return (
    <Swiper modules={[Navigation, A11y, Autoplay]} {...settings}>
      {React.Children.map(children, (child) => (
        <SwiperSlide key={uuid()}>{child}</SwiperSlide>
      ))}
    </Swiper>
  )
}

Slider.defaultProps = {
  defaultSettings: {
    slidesPerView: 1,
    centeredSlides: true,
    grabCursor: true,
    loop: true
  }
}

Slider.propTypes = {
  children: PropTypes.node.isRequired,
  navigationPrev: PropTypes.node,
  navigationNext: PropTypes.node,
  settings: PropTypes.object
}

export default Slider
