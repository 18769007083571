import React from 'react'
import tw, { css } from 'twin.macro'
import { StyledSection, paragraphStyles } from '../../styles'
import firstHowToApplyImg from '../../assets/brands/howtoapply/how-to-apply-01.png'
import HowToApplyList from '../HowToApplyList'
import Contact from '../Contact'

const underlineStyles = css`
  ${tw`absolute opacity-0`};
  left: 50%;
  top: 50%;
  transition: opacity 0.2s ease-in-out;
`

const firstUnderlineStyles = css`
  width: 119%;
  transform: translate(-50%, -50%) scaleY(1.4);
`

const listItems = [
  {
    id: 1,
    content: () => (
      <>
        <span tw="font-thin">
          Share your creative{' '}
          <strong tw="font-medium">
            <span tw="inline-flex relative">
              <span>brief </span>
              <svg
                viewBox="0 0 155 39"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                css={[underlineStyles, firstUnderlineStyles]}
              >
                <path
                  d="M48.5889 9.31835C80.3834 -0.114318 138.817 -4.85399 153.385 17.6673C163.225 36.6419 83.5117 44.5254 20.6472 31.5212C-42.2174 18.5171 56.7441 -5.52804 121.014 5.71017"
                  stroke="#E55708"
                />
              </svg>
            </span>
          </strong>{' '}
          with us.
        </span>
      </>
    ),
    img: firstHowToApplyImg,
    alt: ''
  }
]

const HowToApply = ({ id }) => {
  return (
    <StyledSection tw="flex flex-col justify-center mt-8 lg:mt-32" id={id}>
      <p css={paragraphStyles} tw="relative z-10 mb-10 lg:hidden">
        Follow these steps and join The Dogma Community:
      </p>
      <p css={paragraphStyles} tw="relative z-10 mb-8 hidden lg:block">
        From a digital stunt to a hero piece. We bring world-class talent for all sized budgets.
      </p>
      <HowToApplyList listItems={listItems} />
      <Contact />
    </StyledSection>
  )
}

export { HowToApply }
