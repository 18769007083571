import React from 'react'
import tw, { css } from 'twin.macro'
import { colors } from '../../../config/design-system'
import { paragraphStyles, mediaQuery } from '../../styles'
import { useVideoForViewport } from '../../utils/hooks'
import heroVideoLg from '../../assets/brands/hero/hero.mp4'
import heroVideoSm from '../../assets/brands/hero/hero-mobile.mp4'

const innerSectionStyles = css`
  ${tw`flex flex-wrap md:grid md:grid-cols-12`};
  margin-bottom: 3.75rem;
`

const videoSectionStyles = css`
  ${tw`md:h-screen md:col-start-1 md:col-end-8 md:row-start-1 md:row-end-auto`};
`

const videoStyles = css`
  ${tw`max-h-screen md:w-full md:h-full object-cover`}
  width: 80%;
`

const textSectionStyles = css`
  ${tw`md:col-start-7 md:col-end-13 md:row-start-1 md:row-end-auto md:m-auto md:p-0 z-10`};
  margin-top: -6.5rem;
  padding-left: 2.5rem;
`

const titleStyle = css`
  ${tw`uppercase font-semibold leading-none text-transparent`};
  font-size: 1.75rem;
  margin-bottom: 0.625rem;
  -webkit-text-stroke: 1px ${colors['light-text-color']};
  ${mediaQuery(`
    font-size: 4vw;
  `)}
`

const titleInnerSpan = css`
  color: ${colors['light-text-color']};
  -webkit-text-stroke: none;
`

const paragraphInnerStyle = css`
  max-width: 70%;
`

const Hero = () => {
  const videoSrc = useVideoForViewport(heroVideoSm, heroVideoLg)

  return (
    <section css={innerSectionStyles}>
      <div css={videoSectionStyles}>
        <video src={videoSrc} type="video/mp4" css={videoStyles} autoPlay loop muted playsInline />
      </div>
      <div css={textSectionStyles}>
        <h2 css={titleStyle}>
          Brand
          <br /> content
          <br />
          <span css={titleInnerSpan}>
            <wbr />
            & innovation
            <wbr />
            <br /> studio
            <wbr />
          </span>
        </h2>
        <p css={[paragraphStyles, paragraphInnerStyle]}>
          We craft end-to-end content ecosystems, through our full-service multidisciplinary media
          house, to deliver the most engaging and meaningful content uniting brands and audiences.
        </p>
      </div>
    </section>
  )
}

export { Hero }
